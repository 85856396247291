import React from 'react'

export interface LoaderProps {}

const Loader: React.FC<LoaderProps> = () => {
  return (
    <div className='lds-ripple'>
      <div></div>
      <div></div>
    </div>
  )
}

export default Loader
