import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-location'

import ProfileHeader from './ProfileHeader'

export interface HeaderProps {}

enum MenuType {
  EXTERNAL,
  INTERNAL
}

interface Menu {
  url: string
  title: string
  type?: MenuType
  children?: {
    url: string
    title: string
    type?: MenuType
  }[]
}

const menus: Menu[] = [
  {
    url: '/',
    title: 'Home'
  },
  {
    url: 'pill-factory',
    title: 'PILL Factory',
    children: [
      {
        url: 'pill-factory',
        title: 'PILL Factory'
      },
      {
        url: 'pill-factory-v1',
        title: 'PILL Factory (V1)'
      }
    ]
  },
  {
    title: 'Breed',
    url: '#',
    children: [
      {
        url: 'breeding',
        title: 'Breeding Grounds'
      },
      {
        url: 'renting',
        title: 'Rental Service'
      }
    ]
  },
  {
    url: 'gachapon',
    title: 'Gachapon'
  },
  {
    url: 'wardrobe',
    title: 'Wardrobe'
  },
  {
    title: 'Documentation',
    type: MenuType.EXTERNAL,
    url: 'https://docs.fadeawaybunny.com'
  }
  // {
  //   title: 'Other Pages',
  //   url: '#',
  //   children: [
  //     {
  //       url: 'hall-of-fame',
  //       title: 'Hall of Fame'
  //     },
  //     {
  //       url: 'bunnies/honorary',
  //       title: 'Honorary Collection'
  //     },
  //     {
  //       url: 'bunnies/campaign',
  //       title: 'Campaign Bunnies'
  //     }
  //   ]
  // }
]
const Header: React.FC<HeaderProps> = () => {
  const [showNavMobile, setShowNavMobile] = useState(false)
  const location = useLocation()

  useEffect(() => {
    setShowNavMobile(false)
  }, [location.current.pathname])

  const toggleNavMobile = () => {
    setShowNavMobile(open => !open)
  }

  const renderMenuItem = (item: Menu) => {
    if (item.type === MenuType.EXTERNAL) {
      return (
        <a href={item.url} target='_blank' rel='noreferrer noopener'>
          {item.title}
        </a>
      )
    }

    return (
      <Link
        to={item.url}
        className='hover:text-lightPink'
        getActiveProps={() => ({
          style: {
            color: '#EB539F'
          }
        })}
      >
        {item.title}
      </Link>
    )
  }

  const renderNavMobile = () => {
    return (
      <ul className='menu menu-horizontal fixed top-[58px] md:top-[65px] flex flex-col items-start gap-y-8 min-h-screen w-full py-5 px-6 font-nav font-bold bg-darkPink bg-opacity-80'>
        {menus.map(menu => {
          if (menu.children?.length) {
            return (
              <li tabIndex={0} key={menu.title + menu.url}>
                <a>{menu.title}</a>
                <ul className='p-2 bg-darkPurple z-10'>
                  {menu.children.map(subMenu => (
                    <li key={subMenu.url}>{renderMenuItem(subMenu)}</li>
                  ))}
                </ul>
              </li>
            )
          }

          return <li key={menu.url}>{renderMenuItem(menu)}</li>
        })}
      </ul>
    )
  }

  return (
    <header className='fixed left-0 w-full text-center md:text-justify flex justify-between items-center pr-3 md:pr-6 bg-[#1F0644] bg-opacity-80 z-[9999]'>
      <nav className='flex items-center flex-wrap'>
        <div className='block absolute top-5 left-5 md:hidden' onClick={toggleNavMobile}>
          <div className='space-y-2'>
            <span className='block w-8 h-0.5 bg-white animate-pulse'></span>
            <span className='block w-8 h-0.5 bg-white animate-pulse'></span>
            <span className='block w-8 h-0.5 bg-white animate-pulse'></span>
          </div>
        </div>
        <Link to='/'>
          <img src='/logo.svg' alt='logo' className='hidden md:block w-[12rem]' />
          <img src='/logo_mobile.svg' alt='logo' className='block md:hidden w-[12.5rem]' />
        </Link>
        {showNavMobile && renderNavMobile()}

        <ul className='menu menu-horizontal hidden md:flex gap-x-6 ml-6 font-nav text-sm'>
          {menus.map(menu => {
            if (menu.children?.length) {
              return (
                <li tabIndex={0} key={menu.title + menu.url}>
                  <a>{menu.title}</a>
                  <ul className='p-2 bg-darkPurple'>
                    {menu.children.map(subMenu => (
                      <li key={subMenu.url}>{renderMenuItem(subMenu)}</li>
                    ))}
                  </ul>
                </li>
              )
            }

            return <li key={menu.url}>{renderMenuItem(menu)}</li>
          })}
        </ul>
      </nav>
      <ProfileHeader />
    </header>
  )
}

export default Header
