export async function addChainERC(chainId: number) {
  const provider = (window as any).ethereum
  await provider.request({
    method: 'wallet_switchEthereumChain',
    params: [
      {
        chainId: `0x${chainId.toString(16)}`
      }
    ]
  })
}
