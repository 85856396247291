import { toast } from 'react-toastify'

export const toastSuccess = (message: string | React.ReactNode) => {
  return toast.info(message, {
    type: 'success'
  })
}

export const toastError = (message: string | React.ReactNode) => {
  return toast.info(message, {
    type: 'error'
  })
}

export const toastWarning = (message: string | React.ReactNode) => {
  return toast.info(message, {
    type: 'warning'
  })
}
