export interface Detail {
  description: string
  external_url: string
  image: string
  name: string
  attributes: Attribute[]
  tokenId: string
  isStaked?: boolean
  fertility: string
  itemExpireBlock?: string
  rentPrice?: string
}

export interface Attribute {
  trait_type: string
  value: string
}

export enum Rarity {
  Common = 'Common',
  Uncommon = 'Uncommon',
  Rare = 'Rare',
  Legendary = 'Legendary',
  Mythical = 'Mythical'
}

export interface Metadata {
  'Suicidal Rate': string
  Rarity: Rarity
}
