import React, { useState, useCallback } from 'react'
import { useWeb3React } from '@web3-react/core'

import Button from './Button'
import { useAuth } from 'hooks/useAuth'
import { useBunnyStaked, useMyBunnyDetails, usePillBalance } from 'services/bunny'
import { formatCurrency } from 'utils/format'
import { tokenName } from 'utils/constant'
import WalletConnectButton from './WalletConnectButton'
import { Link } from 'react-location'

export interface ProfileHeaderProps {}

const useClickOutside = (ref: any, callback: any) => {
  const handleClick = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  React.useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const ProfileHeader: React.FC<ProfileHeaderProps> = () => {
  const { account } = useWeb3React()
  const { logout } = useAuth()
  const profileRef = React.useRef()

  const [showProfile, setShowProfile] = useState(false)

  const toggleProfile = useCallback(() => {
    setShowProfile(open => !open)
  }, [])

  useClickOutside(profileRef, () => {
    if (showProfile) {
      setShowProfile(false)
    }
  })

  const { data: pillBalance, isLoading: balanceLoading } = usePillBalance(account || '')
  const { data: myBunnies, isLoading: loadingBunnies } = useMyBunnyDetails(account || '')
  const { data: bunniesStaked, isLoading: loadingStaked } = useBunnyStaked(account || '')

  const handleCopy = (text: string) => async () => {
    return await navigator.clipboard.writeText(text)
  }

  return (
    <div ref={profileRef as any}>
      <WalletConnectButton onAccountClicked={toggleProfile} />

      {showProfile && (
        <div className='absolute right-6 top-[5rem] py-6 px-10 w-full md:w-[26rem] bg-purple shadow-buttonSecondary rounded-lg'>
          <div className='text-center'>
            <div className='flex items-center gap-x-20'>
              <img src='/logo.png' alt='logo' className='w-12 h-12' />
              <div className='flex items-center gap-x-6'>
                <p>
                  {account?.slice(0, 5)}...{account?.slice(-4)}
                </p>
                <img
                  src='/copy.svg'
                  alt='copy'
                  className='w-4 h-4 cursor-pointer'
                  onClick={handleCopy(account || '')}
                />
              </div>
            </div>

            <div className='flex justify-center items-center py-3 mt-5 mb-8 border border-textBlue rounded-full'>
              <img src='/pill_icon.svg' alt='pill' className='absolute left-[3rem] mt-1 w-20' />
              <div>
                <p className='text-sm text-[#7662A5]'>{tokenName} Balance</p>
                <p className='mt-1 font-bold'>{balanceLoading ? '...' : formatCurrency(pillBalance ?? 0)}</p>
              </div>
            </div>

            <Link to={`/bunnies/${account}`}>
              <div className='flex justify-between font-bold'>
                <p>Bunny Collection</p>
                <p>{`${
                  loadingBunnies || loadingStaked ? '...' : (myBunnies?.length ?? 0) + (bunniesStaked?.length ?? 0)
                } Bunnies`}</p>
              </div>
            </Link>
            <div className='h-[1px] w-full my-5 bg-textBlue' />
            <div className='flex justify-between font-bold mb-6'>
              <p>PILL Factory</p>
              <p>{`${loadingStaked ? '...' : bunniesStaked?.length} Staked`}</p>
            </div>

            <Button variant='secondary' onClick={logout}>
              Disconnect
            </Button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ProfileHeader
