import { loggedKey, useAuth } from 'hooks/useAuth'
import React, { useEffect } from 'react'

export interface LayoutProps {}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const { login } = useAuth()

  // auto connect wallet
  useEffect(() => {
    if (window.localStorage.getItem(loggedKey)) {
      login()
    }
  }, [login])

  return <>{children}</>
}

export default Layout
