import { useCallback } from 'react'
import { UnsupportedChainIdError, useWeb3React } from '@web3-react/core'
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected
} from '@web3-react/injected-connector'

import { addChainERC } from 'utils/metamask'
import { injected } from 'utils/connector'

export const loggedKey = '@bunny/loggedIn'

export const useAuth = () => {
  const { account, activate, deactivate } = useWeb3React()

  const login = useCallback(() => {
    const connector = injected

    activate(injected, async (error: Error) => {
      if (error instanceof UnsupportedChainIdError) {
        await addChainERC(+(process.env.REACT_APP_CHAIN_ID as string))
        await activate(connector, async (err2: Error) => {
          if (err2 instanceof UnsupportedChainIdError) {
            return window.alert('Unsupported Chain Id Error. Check your chain Id.')
          }
          if (err2 instanceof NoEthereumProviderError) {
            return window.alert('No provider was found')
          }
          if (err2 instanceof UserRejectedRequestErrorInjected) {
            return window.alert('Authorization Error')
          }

          window.localStorage.removeItem(loggedKey)
          return window.alert(err2.message)
        })
      } else {
        if (error instanceof NoEthereumProviderError) {
          return window.alert('No provider was found')
        }
        if (error instanceof UserRejectedRequestErrorInjected) {
          return window.alert('Authorization Error')
        }

        window.localStorage.removeItem(loggedKey)
        return window.alert(error.message)
      }
    }).then(() => {
      window.localStorage.setItem(loggedKey, 'true')
    })
  }, [activate])

  const logout = useCallback(() => {
    deactivate()
    window.localStorage.removeItem(loggedKey)
    window.location.reload()
  }, [deactivate])

  return { account, login, logout }
}
