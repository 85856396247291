import { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import { Contract } from '@ethersproject/contracts'
import { Web3Provider, JsonRpcProvider } from '@ethersproject/providers'

export const networkProvider = new JsonRpcProvider(process.env.REACT_APP_NETWORK_URL)

export const getSignerOrProvider = (library: Web3Provider, account: string | null | undefined) => {
  return account ? (library as Web3Provider).getSigner(account) : library || networkProvider
}

export const useContract = (address: string | undefined, ABI: any, withSignerIfPossible = true) => {
  const { library, account } = useWeb3React()
  return useMemo(() => {
    if (!address || !ABI) {
      return null
    }

    try {
      if (!withSignerIfPossible) {
        return new Contract(address, ABI, networkProvider)
      }

      if (!library) {
        return null
      }

      return new Contract(address, ABI, getSignerOrProvider(library, account))
    } catch (error) {
      console.error('Failed to get contract', error)
      return null
    }
  }, [address, account, library, ABI, withSignerIfPossible])
}
