import { ReactLocation, Router, Outlet } from 'react-location'
import { useQueryClient } from 'react-query'

import Header from 'components/Header'
import Layout from 'components/Layout'
import { getMyBunnyDetails, myBunnyDetailsQueryKey } from 'services/bunny'
import Loader from 'components/Loader'

function App() {
  const reactLocation = new ReactLocation()
  const queryClient = useQueryClient()

  return (
    <div
      className='w-full min-h-screen text-white bg-darkPurple'
      style={{
        backgroundImage: 'url(/bg.svg)',
        backgroundColor: '#1F0644',
        backgroundSize: 'auto 100vh',
        backgroundRepeat: 'repeat-x'
      }}
    >
      <div className='container max-w-5xl lg:max-w-7xl xl:max-w-[120rem] mx-auto'>
        <Router
          location={reactLocation}
          defaultPendingElement={
            <div className='absolute inset-0 flex justify-center items-center w-screen h-screen bg-black bg-opacity-50'>
              <Loader />
            </div>
          }
          routes={[
            {
              path: '/',
              element: () => import(/* webpackChunkName: "home" */ './containers/Mint').then(page => <page.default />)
            },
            {
              path: 'my-bunny',
              element: () =>
                import(/* webpackChunkName: "my-bunny" */ './containers/MyBunny').then(page => <page.default />)
            },
            {
              path: 'pill-factory',
              element: () =>
                import(/* webpackChunkName: "pill-factory" */ './containers/PillFactory').then(page => <page.default />)
            },
            {
              path: 'pill-factory-v1',
              element: () =>
                import(/* webpackChunkName: "pill-factory" */ './containers/PillFactoryOld').then(page => (
                  <page.default />
                ))
            },
            {
              path: 'breeding',
              element: () =>
                import(/* webpackChunkName: "bunny-breeding" */ './containers/Breeding').then(page => <page.default />)
            },
            {
              path: 'renting',
              element: () =>
                import(/* webpackChunkName: "bunny-renting" */ './containers/Renting').then(page => <page.default />)
            },
            {
              path: 'gachapon',
              element: () =>
                import(/* webpackChunkName: "bunny-lucky-bag" */ './containers/LuckyBag').then(page => <page.default />)
            },
            {
              path: 'wardrobe',
              element: () =>
                import(/* webpackChunkName: "bunny-wardrobe" */ './containers/BunnyWardrobe').then(page => (
                  <page.default />
                ))
            },
            {
              path: 'hall-of-fame',
              element: () =>
                import(/* webpackChunkName: "bunny-hall-of-fame" */ './containers/HallOfFame').then(page => (
                  <page.default />
                ))
            },
            {
              path: 'bunnies',
              children: [
                {
                  path: 'honorary',
                  element: () =>
                    import(/* webpackChunkName: "bunny-honorany" */ './containers/HonoraryBunnies').then(page => (
                      <page.default />
                    ))
                },
                {
                  path: 'campaign',
                  element: () =>
                    import(/* webpackChunkName: "bunny-campaign" */ './containers/CampaignBunnies').then(page => (
                      <page.default />
                    ))
                },
                {
                  path: ':account',
                  element: () =>
                    import(/* webpackChunkName: "bunny-minted" */ './containers/MintedBunnies').then(page => (
                      <page.default />
                    )),
                  loader: async ({ params }) => {
                    return (
                      queryClient.getQueryData(myBunnyDetailsQueryKey(params.account)) ??
                      queryClient.fetchQuery(myBunnyDetailsQueryKey(params.account), () =>
                        getMyBunnyDetails(params.account)
                      )
                    )
                  },
                  pendingMs: 500,
                  pendingMinMs: 500 // If it's shown, ensure the pending element is rendered for at least 500ms
                }
              ]
            }
          ]}
        >
          <Layout>
            <Header />
            <Outlet />
          </Layout>
        </Router>
      </div>
    </div>
  )
}

export default App
