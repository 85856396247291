import React from 'react'

import { useAuth } from 'hooks/useAuth'
import Button from './Button'

export interface WalletConnectButtonProps {
  onAccountClicked?: () => void
}

const WalletConnectButton: React.FC<WalletConnectButtonProps> = ({ onAccountClicked }) => {
  const { account, login } = useAuth()

  return account ? (
    <Button className='font-nav' variant='secondary' onClick={onAccountClicked}>
      {account.slice(0, 4)}...{account.slice(-4)}
    </Button>
  ) : (
    <Button className='font-nav' onClick={login}>
      Connect Wallet
    </Button>
  )
}

export default WalletConnectButton
