import React, { useMemo } from 'react'
import { useWeb3React } from '@web3-react/core'
import clsx from 'clsx'

import {
  useBunnyNFTApproval,
  useGatchaItemApproval,
  useMutateGatchaItemApproval,
  useMutateNFTApproval,
  useMutatePillAllowance,
  usePillAllowance
} from 'services/bunny'
import { useAuth } from 'hooks/useAuth'
import { bunnyBreedingAddress, tokenName } from 'utils/constant'
import Loader from './Loader'

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  variant?: 'primary' | 'secondary'
  isLoading?: boolean
}

export const ButtonModal: React.FC<
  ButtonProps & {
    id: string
    disabled?: boolean
  }
> = ({ children, className, variant = 'primary', id, disabled }) => {
  return (
    <label
      htmlFor={id}
      className={clsx(
        'px-6 py-2 text-white rounded-3xl font-nav transition-all duration-300 modal-button cursor-pointer',
        variant === 'primary'
          ? 'shadow-buttonPrimary hover:shadow-buttonHoverPrimary'
          : 'shadow-buttonSecondary hover:shadow-buttonHoverSecondary',
        disabled && 'bg-gray-400 shadow-none hover:shadow-none cursor-not-allowed',
        className
      )}
    >
      {children}
    </label>
  )
}

const Button: React.FC<ButtonProps> = ({ children, className, variant = 'primary', isLoading, ...btnProps }) => {
  return (
    <div>
      <button
        className={clsx(
          'px-6 py-2 text-white rounded-3xl font-nav transition-all duration-300',
          variant === 'primary'
            ? 'shadow-buttonPrimary hover:shadow-buttonHoverPrimary'
            : 'shadow-buttonSecondary hover:shadow-buttonHoverSecondary',
          btnProps.disabled && 'bg-gray-400 shadow-none hover:shadow-none cursor-not-allowed',
          className
        )}
        {...btnProps}
      >
        {children}
      </button>

      {isLoading && (
        <div className='flex flex-col items-center my-3'>
          <p className='font-bold'>Processing......</p>
          <Loader />
        </div>
      )}
    </div>
  )
}

export const ButtonContract = ({
  children,
  variant = 'primary',
  onClick,
  disabled,
  checkTokenOnly = false,
  checkNFTOnly = false,
  isLoading: loadingAction,
  ...buttonProps
}: ButtonProps & {
  checkTokenOnly?: boolean
  checkNFTOnly?: boolean
}) => {
  const { account } = useWeb3React()
  const { login } = useAuth()
  const { data: allowance, isLoading } = usePillAllowance(account || '', bunnyBreedingAddress || '')
  const { mutate: approve, isLoading: isApproving } = useMutatePillAllowance(account || '', bunnyBreedingAddress || '')

  const { data: nftApproved, isLoading: loadingNFT } = useBunnyNFTApproval(account || '', bunnyBreedingAddress || '')
  const { mutate: approveNFT, isLoading: isNFTApproving } = useMutateNFTApproval(
    account || '',
    bunnyBreedingAddress || ''
  )

  const loadingState = useMemo(
    () => loadingAction || isLoading || isApproving || loadingNFT || isNFTApproving,
    [loadingAction, isLoading, isApproving, loadingNFT, isNFTApproving]
  )

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!account) {
      return login()
    }

    if (!checkNFTOnly && (!allowance || +allowance < 10000)) {
      return approve()
    }

    if (!checkTokenOnly && !nftApproved) {
      return approveNFT()
    }

    return onClick?.(e)
  }

  const renderChild = () => {
    if (!account) {
      return 'Connect Wallet'
    }

    if (isLoading || isApproving || loadingNFT || isNFTApproving) {
      return 'Checking approval...'
    }

    if (!checkNFTOnly && (!allowance || +allowance < 10000)) {
      return `Approve ${tokenName}`
    }

    if (!checkTokenOnly && !nftApproved) {
      return 'Approve Bunnies'
    }

    return children
  }

  return (
    <div>
      <Button variant={variant} disabled={!account || loadingState || disabled} onClick={handleClick} {...buttonProps}>
        {renderChild()}
      </Button>
      {loadingState && (
        <div className='flex flex-col items-center mt-3'>
          <p className='font-bold'>Processing......</p>
          <Loader />
        </div>
      )}
    </div>
  )
}

export const ButtonGatchaContract = ({
  children,
  variant = 'primary',
  onClick,
  disabled,
  checkTokenOnly = false,
  isLoading: loadingAction,
  ...buttonProps
}: ButtonProps & {
  checkTokenOnly?: boolean
}) => {
  const { account } = useWeb3React()
  const { login } = useAuth()
  const { data: allowance, isLoading } = usePillAllowance(account || '', bunnyBreedingAddress || '')
  const { mutate: approve, isLoading: isApproving } = useMutatePillAllowance(account || '', bunnyBreedingAddress || '')

  const { data: nftApproved, isLoading: loadingNFT } = useGatchaItemApproval(account || '', bunnyBreedingAddress || '')
  const { mutate: approveNFT, isLoading: isNFTApproving } = useMutateGatchaItemApproval(
    account || '',
    bunnyBreedingAddress || ''
  )

  const loadingState = useMemo(
    () => loadingAction || isLoading || isApproving || loadingNFT || isNFTApproving,
    [loadingAction, isLoading, isApproving, loadingNFT, isNFTApproving]
  )

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (!account) {
      return login()
    }

    if (!allowance || +allowance < 10000) {
      return approve()
    }

    if (!checkTokenOnly && !nftApproved) {
      return approveNFT()
    }

    return onClick?.(e)
  }

  const renderChild = () => {
    if (!account) {
      return 'Connect Wallet'
    }

    if (isLoading || isApproving || loadingNFT || isNFTApproving) {
      return 'Checking approval...'
    }

    if (!allowance || +allowance < 10000) {
      return `Approve ${tokenName}`
    }

    if (!checkTokenOnly && !nftApproved) {
      return 'Approve Gatcha'
    }

    return children
  }

  return (
    <div className='mt-3'>
      <Button variant={variant} disabled={!account || loadingState || disabled} onClick={handleClick} {...buttonProps}>
        {renderChild()}
      </Button>
      {loadingState && (
        <div className='flex flex-col items-center my-3'>
          <p className='font-bold'>Processing......</p>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default Button
