import { Contract } from '@ethersproject/contracts'
import { useQuery, useQueryClient, useMutation } from 'react-query'
import { request, gql } from 'graphql-request'
import { BigNumber, constants } from 'ethers'
import { formatEther } from 'ethers/lib/utils'
import { networkProvider, useContract } from 'hooks/useContract'
import { Detail } from 'types/detail'
import {
  bunnyNFTAddress,
  bunnyStakeAddress,
  bunnyStakeAddressOld,
  apiUrl,
  pillTokenAddress,
  bunnyBreedingAddress,
  gatchaItemAddress,
  graphUrl
} from 'utils/constant'

import bunnyABI from 'abis/bunnyNFT.json'
import bunnyStakeABI from 'abis/bunnyStake.json'
import bunnyStakeABIOld from 'abis/bunnyStakeOld.json'
import erc20ABI from 'abis/erc20.json'
import erc721ABI from 'abis/erc721.json'
import erc1155ABI from 'abis/erc1155.json'
import { toastSuccess } from 'utils/toast'
import { StakingBunny } from 'types/bunny'

const bunnyContract = new Contract(bunnyNFTAddress || '', bunnyABI, networkProvider)
const breedingContract = new Contract(bunnyBreedingAddress || '', bunnyStakeABI, networkProvider)
const endpoint = graphUrl || ''

export const getBunnyDetail = async (tokenId: number): Promise<Detail> => {
  const detail = await fetch(apiUrl + `/tokens/${tokenId}`).then(res => res.json())
  const fertility = await breedingContract?.getFertility(tokenId)

  return {
    ...detail,
    fertility: fertility.toString()
  }
}

export const getMyBunnies = async (account: string) => {
  if (account) {
    const balance = await bunnyContract.balanceOf(account)
    const total = +balance.toString() ?? 0
    const promises = []

    for (let i = 0; i < total; i++) {
      promises.push(bunnyContract.tokenOfOwnerByIndex(account, i))
    }

    const settled = await Promise.allSettled(promises)
    const myTokens = settled
      .map(item => {
        if (item.status === 'fulfilled') {
          return item.value
        }

        return undefined
      })
      .filter(x => x)
      .sort((a, b) => a - b)

    return myTokens
  }
}

export const getMyBunnyDetails = async (account: string) => {
  const myBunnies = await getMyBunnies(account)
  if (myBunnies && myBunnies.length > 0) {
    const promises: Promise<Detail>[] = []

    myBunnies.forEach(bunny => promises.push(getBunnyDetail(bunny)))

    const details = await Promise.all(promises)
    return details.map((detail, index) => ({
      ...detail,
      tokenId: myBunnies[index].toString()
    }))
  }

  return []
}

export const rewardPerDayQueryKey = '@bunny_reward_perday'
export const useRewardPerDay = () => {
  const stakingContract = useContract(bunnyStakeAddress, bunnyStakeABI, false)
  return useQuery<number>(
    rewardPerDayQueryKey,
    async () => {
      const reward = await stakingContract?.rewardPerDay()

      return +formatEther(reward)
    },
    {
      enabled: !!stakingContract
    }
  )
}

export const bunnyRateQueryKey = '@bunny_rate'
export const useBunnyRate = (tokenId: string) => {
  const stakingContract = useContract(bunnyStakeAddress, bunnyStakeABI, false)
  return useQuery<number>(
    bunnyRateQueryKey,
    async () => {
      const rate = await stakingContract?.viewNftRate(tokenId)

      return rate / 10000
    },
    {
      enabled: !!stakingContract && !!tokenId
    }
  )
}

export const myBunnyDetailsQueryKey = (account: string) => `@my_bunny_details/${account}`
export const useMyBunnyDetails = (account: string) => {
  const queryClient = useQueryClient()

  return {
    ...useQuery<Detail[]>(myBunnyDetailsQueryKey(account), () => getMyBunnyDetails(account), {
      enabled: !!account
    }),
    invalidate: () => queryClient.invalidateQueries(myBunnyDetailsQueryKey(account))
  }
}

export const bunnyRewardRateQueryKey = '@bunny_reward_rate'
export const useBunnyRewardRate = (tokenId: string) => {
  const { data: reward } = useRewardPerDay()
  const { data: rate } = useBunnyRate(tokenId)

  if (rate && reward) {
    return rate * reward
  }

  return 0
}

export const bunnyStakedQueryKey = (account: string) => `@bunny_staked/${account}`
export const useBunnyStaked = (account: string) => {
  const queryClient = useQueryClient()

  return {
    ...useQuery<Detail[]>(
      bunnyStakedQueryKey(account),
      async () => {
        const { stakings } = await request(
          endpoint,
          gql`
          query {
            stakings(where: { owner: "${account}" }) {
              id
              owner,
              itemExpireBlock
            }
          }
        `
        )

        // const bunnyIds = stakings.map((item: StakingBunny) => +item.id).sort((a: number, b: number) => a - b)
        if (stakings?.length > 0) {
          const promises: Promise<Detail>[] = []

          stakings.forEach((staking: StakingBunny) => promises.push(getBunnyDetail(+staking.id)))

          const details = await Promise.all(promises)
          return details
            .map((detail, index) => ({
              ...detail,
              tokenId: stakings[index].id,
              isStaked: true,
              itemExpireBlock: +stakings[index].itemExpireBlock > 0 ? stakings[index].itemExpireBlock : undefined
            }))
            .sort((a: Detail, b: Detail) => +a.tokenId - +b.tokenId)
        }

        return []
      },
      {
        enabled: !!account
      }
    ),
    invalidate: () => queryClient.invalidateQueries(bunnyStakedQueryKey(account))
  }
}

export const bunnyStakedQueryKeyOld = (account: string) => `@bunny_staked_old/${account}`
export const useBunnyStakedOld = (account: string) => {
  const queryClient = useQueryClient()
  const stakingContract = useContract(bunnyStakeAddressOld, bunnyStakeABIOld, false)

  return {
    ...useQuery<Detail[]>(
      bunnyStakedQueryKeyOld(account),
      async () => {
        const data = await stakingContract?.depositsOf(account)

        const bunnyIds = data.map((item: BigNumber) => +item.toString()).sort((a: number, b: number) => a - b)
        if (bunnyIds?.length > 0) {
          const promises: Promise<Detail>[] = []

          bunnyIds.forEach((bunny: number) => promises.push(getBunnyDetail(bunny)))

          const details = await Promise.all(promises)
          return details.map((detail, index) => ({
            ...detail,
            tokenId: bunnyIds[index].toString(),
            isStaked: true
          }))
        }

        return []
      },
      {
        enabled: !!account
      }
    ),
    invalidate: () => queryClient.invalidateQueries(bunnyStakedQueryKeyOld(account))
  }
}

export const getBunnyRewardAmount = async (account: string, tokenId: string) => {
  const stakingContract = new Contract(bunnyStakeAddress || '', bunnyStakeABI, networkProvider)
  const amount = await stakingContract.viewReward(account, tokenId)

  return formatEther(amount)
}

export const getBunnyRewardAmountOld = async (account: string, tokenId: string) => {
  const stakingContract = new Contract(bunnyStakeAddressOld || '', bunnyStakeABIOld, networkProvider)
  const amount = await stakingContract.viewReward(account, tokenId)

  return formatEther(amount)
}

export const bunnyRewardAmountQueryKey = (account: string) => `@bunny_reward_amount/${account}`
export const useBunnyRewardAmount = (account: string) => {
  const queryClient = useQueryClient()
  const stakingContract = useContract(bunnyStakeAddress, bunnyStakeABI, false)

  return {
    ...useQuery<number>(
      bunnyRewardAmountQueryKey(account),
      async () => {
        const data = await stakingContract?.depositsOf(account)

        const bunnyIds = data.map((item: BigNumber) => item.toString())
        if (bunnyIds?.length > 0) {
          const promises: Promise<string>[] = []

          bunnyIds.forEach((bunny: string) => promises.push(getBunnyRewardAmount(account || '', bunny)))

          const details = await Promise.all(promises)
          return details.reduce((acc, cur) => +acc + +cur, 0)
        }

        return 0
      },
      {
        enabled: !!account,
        refetchInterval: 30000
      }
    ),
    invalidate: () => queryClient.invalidateQueries(bunnyRewardAmountQueryKey(account))
  }
}

export const bunnyRewardAmountQueryKeyOld = (account: string) => `@bunny_reward_amount_old/${account}`
export const useBunnyRewardAmountOld = (account: string) => {
  const queryClient = useQueryClient()
  const stakingContract = useContract(bunnyStakeAddressOld, bunnyStakeABIOld, false)

  return {
    ...useQuery<number>(
      bunnyRewardAmountQueryKeyOld(account),
      async () => {
        const data = await stakingContract?.depositsOf(account)

        const bunnyIds = data.map((item: BigNumber) => item.toString())
        if (bunnyIds?.length > 0) {
          const promises: Promise<string>[] = []

          bunnyIds.forEach((bunny: string) => promises.push(getBunnyRewardAmountOld(account || '', bunny)))

          const details = await Promise.all(promises)
          return details.reduce((acc, cur) => +acc + +cur, 0)
        }

        return 0
      },
      {
        enabled: !!account,
        refetchInterval: 30000
      }
    ),
    invalidate: () => queryClient.invalidateQueries(bunnyRewardAmountQueryKeyOld(account))
  }
}

export const pillBalanceQueryKey = (account: string) => `@bunny_pill_balance/${account}`
export const usePillBalance = (account: string) => {
  const queryClient = useQueryClient()
  const pillContract = useContract(pillTokenAddress, erc20ABI, false)

  return {
    ...useQuery<number>(
      pillBalanceQueryKey(account),
      async () => {
        const data = await pillContract?.balanceOf(account)

        return +formatEther(data)
      },
      {
        enabled: !!account
      }
    ),
    invalidate: () => queryClient.invalidateQueries(pillBalanceQueryKey(account))
  }
}

export const pillAllowanceQueryKey = (account: string, spender: string) => `@bunny_pill_allowance/${account}/${spender}`
export const usePillAllowance = (account: string, spender: string) => {
  const queryClient = useQueryClient()
  const pillContract = useContract(pillTokenAddress, erc20ABI, false)

  return {
    ...useQuery<string>(
      pillAllowanceQueryKey(account, spender),
      async () => {
        const data = await pillContract?.allowance(account, spender)

        return formatEther(data)
      },
      {
        enabled: !!account
      }
    ),
    invalidate: () => queryClient.invalidateQueries(pillAllowanceQueryKey(account, spender))
  }
}

export const useMutatePillAllowance = (account: string, spender: string) => {
  const queryClient = useQueryClient()
  const pillContract = useContract(pillTokenAddress, erc20ABI)

  return useMutation(
    async () => {
      const tx = await pillContract?.approve(spender, constants.MaxUint256)
      await tx.wait()
    },
    {
      onSuccess: () => {
        toastSuccess('$Pill Approved')
        setTimeout(() => queryClient.invalidateQueries(pillAllowanceQueryKey(account, spender)), 1000)
      }
    }
  )
}

export const nftApprovalQueryKey = (account: string, spender: string) => `@bunny_nft_approval/${account}/${spender}`
export const useBunnyNFTApproval = (account: string, spender: string) => {
  const queryClient = useQueryClient()
  const nftContract = useContract(bunnyNFTAddress, erc721ABI, false)

  return {
    ...useQuery<string>(
      nftApprovalQueryKey(account, spender),
      async () => {
        const isApproved = await nftContract?.isApprovedForAll(account, spender)

        return isApproved
      },
      {
        enabled: !!account
      }
    ),
    invalidate: () => queryClient.invalidateQueries(nftApprovalQueryKey(account, spender))
  }
}

export const useMutateNFTApproval = (account: string, spender: string) => {
  const queryClient = useQueryClient()
  const nftContract = useContract(bunnyNFTAddress, erc721ABI)

  return useMutation(
    async () => {
      const tx = await nftContract?.setApprovalForAll(spender, true)
      await tx.wait()
    },
    {
      onSuccess: () => {
        toastSuccess('Bunny NFT Approved')
        setTimeout(() => queryClient.invalidateQueries(nftApprovalQueryKey(account, spender)), 1000)
      }
    }
  )
}

export const currentMintQueryKey = `@bunny_current_mint`
export const useBunnyCurrentMint = () => {
  const queryClient = useQueryClient()

  return {
    ...useQuery<number>(
      currentMintQueryKey,
      async () => {
        const data = await bunnyContract?.totalSupply()

        return +data
      },
      {
        refetchInterval: 10 * 60 * 1000 // 10mins
      }
    ),
    invalidate: () => queryClient.invalidateQueries(currentMintQueryKey)
  }
}

export const gatchaItemApprovalQueryKey = (account: string, spender: string) =>
  `@gatcha_item_approval/${account}/${spender}`
export const useGatchaItemApproval = (account: string, spender: string) => {
  const queryClient = useQueryClient()
  const nftContract = useContract(gatchaItemAddress, erc1155ABI, false)

  return {
    ...useQuery<string>(
      gatchaItemApprovalQueryKey(account, spender),
      async () => {
        const isApproved = await nftContract?.isApprovedForAll(account, spender)

        return isApproved
      },
      {
        enabled: !!account
      }
    ),
    invalidate: () => queryClient.invalidateQueries(gatchaItemApprovalQueryKey(account, spender))
  }
}

export const useMutateGatchaItemApproval = (account: string, spender: string) => {
  const queryClient = useQueryClient()
  const nftContract = useContract(gatchaItemAddress, erc1155ABI)

  return useMutation(
    async () => {
      const tx = await nftContract?.setApprovalForAll(spender, true)
      await tx.wait()
    },
    {
      onSuccess: () => {
        toastSuccess('Gatcha Item Approved')
        setTimeout(() => queryClient.invalidateQueries(gatchaItemApprovalQueryKey(account, spender)), 1000)
      }
    }
  )
}
