export const bunnyGeneratorAddress = process.env.REACT_APP_MINT_CONTRACT
export const bunnyNFTAddress = process.env.REACT_APP_NFT_CONTRACT
export const bunnyStakeAddress = process.env.REACT_APP_BREEDING_CONTRACT
export const bunnyStakeAddressOld = '0x8bc55567a68aa14162f56f8f7a75c6be04757fba'
export const bunnyBreedingAddress = process.env.REACT_APP_BREEDING_CONTRACT
export const pillTokenAddress = process.env.REACT_APP_PILL_CONTRACT
export const gatchaItemAddress = process.env.REACT_APP_GATCHA_ITEM_CONTRACT
export const graphUrl = process.env.REACT_APP_GRAPH_API
export const gatchaGraphUrl = process.env.REACT_APP_GATCHA_GRAPH_API
export const explorerUrl = process.env.REACT_APP_EXPLORER_URL
export const apiUrl = process.env.REACT_APP_API_URL
export const gatchaApiUrl = process.env.REACT_APP_GATCHA_API_URL
export const networkUrl = process.env.REACT_APP_NETWORK_URL
export const tokenName = '$PILL'

export const maxBunnyMint = 300
export const BLOCK_PER_DAY = 7200
