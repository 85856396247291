import { Attribute, Metadata, Rarity } from 'types/detail'

export const formatCurrency = (price: number) => {
  return new Intl.NumberFormat('en-US').format(price)
}

export const formatAttributes = (attributes: Attribute[]) => {
  return attributes.reduce<Metadata>((acc, cur) => {
    return {
      ...acc,
      [cur.trait_type]: cur.value
    }
  }, {} as Metadata)
}

export const rarityToWeight = {
  [Rarity.Common]: 1,
  [Rarity.Uncommon]: 2,
  [Rarity.Rare]: 3,
  [Rarity.Legendary]: 4,
  [Rarity.Mythical]: 5
}
